// import all images from assets/images directory
import img07 from "../all-images/decks_bathroom/01.jpg";
import img08 from "../all-images/decks_bathroom/02.jpg";
import img09 from "../all-images/decks_bathroom/03.jpg";
import img01 from "../all-images/decks/07.jpg";
import img02 from "../all-images/decks/08.jpg";
import img03 from "../all-images/decks/09.jpg";

const HomeData =  [{
    id: 1,
    brand: "decks",
    rating: 112,
    joiaName: "Decks Serenidade",
    imgUrl: img01,
    model: "Decks",
    price: 50,
    description:
      " O Brinco Serenidade é uma peça elegante e moderna, feita em dourado, com um design minimalista e geométrico. É a escolha perfeita para quem procura um toque de sofisticação e simplicidade em seus acessórios, adicionando um brilho dourado discreto, porém impactante, ao visual.",
  },
  {
    id: 2,
    brand: "decks",
    rating: 102,
    joiaName: "Decks Ébano",
    imgUrl: img02,
    model: "Decks",
    price: 50,
    description:
      " Os Decks Ébano são peças de joalheria elegantes e ousadas, feitas com detalhes em ébano, combinada com metais preciosos. Esses Decks são ideais para quem busca um toque de mistério e sofisticação, adicionando uma dose de elegância e contraste ao visual com sua paleta de cores ricas e textura única.",
  },

  {
    id: 3,
    brand: "decks",
    rating: 132,
    joiaName: "Decks Amiga",
    imgUrl: img03,
    model: "Decks",
    price: 65,
    description:
      " Os Decks Amiga de Cobra são acessórios de moda que apresentam um design exclusivo inspirado nas escamas de cobra. Estes Decks são uma homenagem à amizade, simbolizando a força e a lealdade que as amizades verdadeiras representam. Com seu estilo distinto e atrevido, os Decks Amiga de Cobra são uma maneira única de celebrar e mostrar apreço por uma amizade especial.",
  },

  {
    id: 7,
    brand: "decks_bathroom",
    rating: 102,
    joiaName: "Deck Banheira Aurora",
    imgUrl: img07,
    model: "Decks Bathroom",
    price: 70,
    description:
      " O Colar Estilo Aurora é uma peça de joalheria que irradia elegância e sofisticação. Com detalhes finos e incrustado de brilhantes, este colar é um exemplo de requinte atemporal. Perfeito para adicionar um toque de glamour a qualquer visual, é uma escolha clássica para momentos especiais e ocasiões que merecem um toque de brilho extra.",
  },

  {
    id: 8,
    brand: "decks_bathroom",
    rating: 94,
    joiaName: "Deck Banheira do Crepúsculo",
    imgUrl: img08,
    model: "Decks Bathroom",
    price: 45,
    description:
      " Diamantes no Crepúsculo é uma coleção deslumbrante de joias que captura a beleza cintilante e mágica do entardecer. Cada peça é uma obra-prima de design, incorporando diamantes deslumbrantes que brilham como estrelas no céu durante o crepúsculo. Essas joias são perfeitas para quem deseja adicionar um toque de fascinação e elegância ao seu visual, lembrando a tranquilidade e a beleza do momento em que o sol se põe.",
  },

  {
    id: 9,
    brand: "decks_bathroom",
    rating: 119,
    joiaName: "Deck Banheira Esmeralda",
    imgUrl: img09,
    model: "Decks Bathroom",
    price: 85,
    description:
      "Esmeralda da Manhã é uma coleção encantadora de joias inspirada na beleza serena e refrescante das manhãs. Cada peça desta coleção é adornada com esmeraldas deslumbrantes que evocam a frescura e a vitalidade da natureza na primeira luz do dia. Estas joias são uma escolha perfeita para quem busca a elegância das esmeraldas combinada com a sensação revigorante e inspiradora das manhãs ensolaradas.",
  }
];

export default HomeData;
