import React from "react";
import Helmet from "../components/Helmet/Helmet";
import Brincos from "./Listen's/DecksListing"

const Listing = () => {
  return (
    <Helmet title="Joias">

      <Brincos/>
      
    </Helmet>
  );
};

export default Listing;
