import React from "react";
import HeroSlider from "../components/UI/Slider's/HeroSlider";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import AboutSection from "../components/UI/AboutSection";
import HomeData from "../assets/data/HomeData";
import ServiceItem from "../components/UI/serviceItem";
import BecomeSection from "../components/UI/BecomeSection";
import Testimonial from "../components/UI/Testimonial";

const Home = () => {
  return (
    <Helmet title="Home">
      <section className="p-0 hero__slider-section">
        <HeroSlider />
      </section>

      {/* =========== about section ================ */}
      
      <AboutSection />
      
      {/* =========== CATEGORY section ============= */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
               <h6 className="section__subtitle">ESCOLHER POR CATEGORIA</h6>
               <h2 className="section__title">Encontre seu serviço</h2>
            </Col>

            {HomeData.slice(0, 6).map((item) => (
              <ServiceItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
      <BecomeSection />

      {/* =========== testimonial section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h2 className="section__title">O que dizem nossos clientes</h2>
              {/* <h2 className="section__title">Testimonials</h2> */}
            </Col>

            <Testimonial />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
