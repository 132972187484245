import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import CommonSection from "../../components/UI/CommonSection";
import DecksItem from "../../components/UI/Decks/DecksItem";
import DecksData from "../../assets/data/DeckData";
import SliderDecks from "../../components/UI/Slider's/SliderDecks";

const Listing = () => {
  return (
    <Helmet title="Decks">
      <CommonSection title="Decks" />
      
      <SliderDecks/>
      <section>
        <Container>
          <Row>
            <Col lg="12">
            </Col>

            {DecksData.map((item) => (
              <DecksItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Listing;
