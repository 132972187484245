import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/user_user.jpg";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h2 className="section__title">Bem vindo!!</h2>
              <br />
              <h1 className="section__title">Mozini Decks</h1>
              <p className="section__description">
              
              No mundo dos Decks.
              <br />
              <br />
              No mundo dos Decks.
              <br />
              <br />
              No mundo dos Decks
              <br />
              <br />
              No mundo dos Decks
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
