import React, { useEffect } from "react";

import HomeData from "../../assets/data/HomeData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import { useParams } from "react-router-dom";

const Details_Home = () => {
  const { slug } = useParams();

  const singleJoiaItem = HomeData.find((item) => item.joiaName === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleJoiaItem]);

  return (
    <Helmet title={singleJoiaItem.joiaName}>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <img src={singleJoiaItem.imgUrl} alt="" className="w-100" />
            </Col>

            <Col lg="6">
              <div className="car__info">
                <h2 className="section__title">{singleJoiaItem.joiaName}</h2>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    ${singleJoiaItem.price}.00 
                  </h6>

                  <span className=" d-flex align-items-center gap-2">
                    <span style={{ color: "#f9a826" }}>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                    </span>
                    ({singleJoiaItem.rating} ratings)
                  </span>
                </div>

                <p className="section__description">
                  {singleJoiaItem.description}
                </p>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "4rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-vip-diamond-fill"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleJoiaItem.model}
                  </span>
                </div>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "2.8rem" }}
                >

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-building-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleJoiaItem.brand}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Details_Home;
