import img01 from "../all-images/decks/07.jpg";
import img02 from "../all-images/decks/08.jpg";
import img03 from "../all-images/decks/09.jpg";

const BrincoData = [
{
  id: 1,
  brand: "decks",
  rating: 112,
  joiaName: "Decks Serenidade",
  imgUrl: img01,
  model: "Decks",
  price: 50,
  description:
    " O Brinco Serenidade é uma peça elegante e moderna, feita em dourado, com um design minimalista e geométrico. É a escolha perfeita para quem procura um toque de sofisticação e simplicidade em seus acessórios, adicionando um brilho dourado discreto, porém impactante, ao visual.",
},
{
  id: 2,
  brand: "decks",
  rating: 102,
  joiaName: "Decks Ébano",
  imgUrl: img02,
  model: "Decks",
  price: 50,
  description:
    " Os Decks Ébano são peças de joalheria elegantes e ousadas, feitas com detalhes em ébano, combinada com metais preciosos. Esses Decks são ideais para quem busca um toque de mistério e sofisticação, adicionando uma dose de elegância e contraste ao visual com sua paleta de cores ricas e textura única.",
},

{
  id: 3,
  brand: "decks",
  rating: 132,
  joiaName: "Decks Amiga",
  imgUrl: img03,
  model: "Decks",
  price: 65,
  description:
    " Os Decks Amiga de Cobra são acessórios de moda que apresentam um design exclusivo inspirado nas escamas de cobra. Estes Decks são uma homenagem à amizade, simbolizando a força e a lealdade que as amizades verdadeiras representam. Com seu estilo distinto e atrevido, os Decks Amiga de Cobra são uma maneira única de celebrar e mostrar apreço por uma amizade especial.",
},
];

export default BrincoData;
